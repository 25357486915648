import * as React from "react"
import { graphql } from 'gatsby'
import i18next from '../i18n.js'

import CategoryTile from "../components/categoryTile"
import Layout from "../components/layout"

import * as Style from "../styles/categories.module.scss"

const CategoryPage = ({ data, pageContext }) => {
  i18next.changeLanguage('de')
  var  {t} = i18next

  return (
    <Layout title={t('cagegoryPageTitle')}
      pageContext={pageContext}>
      <div class={Style.page}> 
        
        <p>{t('categoryPageIntroduction')}</p>


        <div class={Style.categoryGrid}>
          {data.allAirtableCategories.nodes.map(category => (
            <CategoryTile category={category}
                          text={t('categoryTileArticles')}
                          locale='de' />
          ))}

        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query CategoriesDE   {
    allAirtableCategories(
      sort: {fields: data___Name}
      filter: {data: {Locale: {eq: "de"}}}
    ) {
    nodes {
      data {
        Name
        Slug
        Markdown {
          childMdx {
            body
          }
        }
      }
      posts {
        frontmatter {
          title
          locale
          date(formatString: "D. MMMM YYYY", 
              locale: "de")
        }
        slug
        
      }
    }
  }
  
}
  
  
`

export default CategoryPage