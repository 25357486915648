import * as React from 'react'
import Link from './localizedLink'
import * as Styles from '../styles/categories.module.scss'
//import { I18nextContext } from "gatsby-plugin-react-i18next"

const CategoryTile = ({ category, 
                        text,
                      locale }) => {
// const context = React.useContext(I18nextContext)

  return (

    <div class={Styles.categoryTile}>
      <Link to={category.data.Slug.toLowerCase()}>
        <div >
          <h3>{category.data.Name}</h3>
          {category.posts !== undefined ? (<div>

            <p>{text}</p>
            <ul> 
              {category.posts
                .filter(function (a) { return a.frontmatter.locale === locale })
                .sort(function (a, b) { return a.frontmatter.date < b.frontmatter.date ? 1 : -1 })
                .slice(0, 5).map(post => (
                  <li><Link to={`/blog/${post.slug}`}
                            locale={locale}>{post.frontmatter.date} - {post.frontmatter.title.length > 30
                    ? post.frontmatter.title.slice(0, 30) + "..."
                    : post.frontmatter.title}</Link></li>
                ))}
            </ul>
          </ div>)
            : (<div></div>)}
        </div>
      </Link>
    </div>
  )
}
export default CategoryTile
  /*   
    category{ 
      data {
        Name
        Slug
        Markdown {
          childMdx {
            body
          }
        }
      }
      posts {
        frontmatter {
          title
          locale
        }
        slug
      }
    }*/